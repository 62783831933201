
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import BackupCodes from '@/components/shared/templates/BackupCodes.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { codes } from '@/definitions/_general/_data/backupCodes'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmAlert,
    TmTooltip,
    BackupCodes,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const generatingCodesValue = ref(false)
    const generatingCodes = () => {
      generatingCodesValue.value = true
      setTimeout(() => {
        generatingCodesValue.value = false
      }, 4000)
    }

    const copiedStatus = ref(false)
    const copyCodes = () => {
      copiedStatus.value = true
    }

    return {
      isSmMax,
      codes,
      generatingCodesValue,
      generatingCodes,
      copiedStatus,
      copyCodes,
    }
  },
})
